/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const filterUsers = /* GraphQL */ `
  query FilterUsers($email: String, $limit: Int, $nextToken: String) {
    filterUsers(email: $email, limit: $limit, nextToken: $nextToken) {
      items {
        id
        patientId
        dateOfBirth
        email
        phoneNumber
        equipments
        painAreas
        firstName
        locale
        program
        membership {
          refKey
          inviteToken
          __typename
        }
        membershipStatus
        membershipStartedAt
        gender
        hrMax
        hrMin
        name
        sensorsConfigured {
          modelName
          name
          tag
          type
          additionalData {
            macAddress
            __typename
          }
          brand
          __typename
        }
        sessionsCompleted
        walkSessionsCompleted
        weight
        height
        versionInfo {
          appVersion
          os
          osVersion
          platform
          version
          __typename
        }
        avatar
        balance
        balanceLevel
        balanceCardiacFeedback
        balanceFeedback
        endurance
        enduranceLevel
        enduranceCardiacFeedback
        enduranceFeedback
        power
        powerLevel
        powerCardiacFeedback
        powerFeedback
        resistance
        resistanceLevel
        resistanceCardiacFeedback
        resistanceFeedback
        recommendations {
          value
          input
          createdAt
          expiresAt
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAlixMedExercise = /* GraphQL */ `
  query GetAlixMedExercise($id: ID!) {
    getAlixMedExercise(id: $id) {
      id
      advice {
        fr
        en
        nl
        es
        it
        de
        __typename
      }
      bodyParts {
        id
        __typename
      }
      capacity
      cardiacImpact
      categories {
        id
        __typename
      }
      difficulty
      equipments {
        id
        name
        __typename
      }
      painAreas
      family
      intensity
      muscularContraction
      musclesInvolved {
        id
        __typename
      }
      name
      tags
      variants {
        id
        name
        videos {
          alex {
            default {
              filepath
              fileSize
              __typename
            }
            high {
              filepath
              fileSize
              __typename
            }
            __typename
          }
          nina {
            default {
              filepath
              fileSize
              __typename
            }
            high {
              filepath
              fileSize
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      workoutPrograms
      easyIDs
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAlixMedExercises = /* GraphQL */ `
  query ListAlixMedExercises(
    $filter: ModelAlixMedExerciseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlixMedExercises(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        advice {
          fr
          en
          nl
          es
          it
          de
          __typename
        }
        bodyParts {
          id
          __typename
        }
        capacity
        cardiacImpact
        categories {
          id
          __typename
        }
        difficulty
        equipments {
          id
          name
          __typename
        }
        painAreas
        family
        intensity
        muscularContraction
        musclesInvolved {
          id
          __typename
        }
        name
        tags
        variants {
          id
          name
          videos {
            alex {
              __typename
            }
            nina {
              __typename
            }
            __typename
          }
          __typename
        }
        workoutPrograms
        easyIDs
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAlixMedSession = /* GraphQL */ `
  query GetAlixMedSession($id: ID!) {
    getAlixMedSession(id: $id) {
      id
      events {
        type
        timestamp
        data {
          index
          type
          __typename
        }
        __typename
      }
      exercisesData {
        name
        author
        duration
        components {
          section
          exerciseVariantId
          exerciseId
          name
          pause
          duration
          speedFactor
          intensity
          video {
            filepath
            filesize
            __typename
          }
          equipments
          painAreas
          cardiacImpact
          easyMode {
            exerciseId
            exerciseVariantId
            name
            video {
              filepath
              filesize
              __typename
            }
            equipments
            cardiacImpact
            advice
            __typename
          }
          advice
          walkSessionData {
            distance
            averageSpeed
            averagePace
            __typename
          }
          __typename
        }
        equipments
        module
        moduleLevel
        program
        iterations
        walkSessionData {
          distance
          averageSpeed
          averagePace
          __typename
        }
        type
        description
        advice
        __typename
      }
      sessionInfo {
        startTime
        endTime
        userFeedback
        cardiacFeedback
        sensorDataTimeseries {
          hr {
            min
            max
            avg
            __typename
          }
          __typename
        }
        __typename
      }
      timestamp
      userId
      sensor {
        modelName
        name
        tag
        type
        additionalData {
          macAddress
          __typename
        }
        brand
        __typename
      }
      sessionTimeSeries
      userInfo {
        dateOfBirth
        weight
        height
        hrMin
        hrMax
        gender
        __typename
      }
      program
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAlixMedSessions = /* GraphQL */ `
  query ListAlixMedSessions(
    $filter: ModelAlixMedSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlixMedSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        events {
          type
          timestamp
          data {
            index
            type
            __typename
          }
          __typename
        }
        exercisesData {
          name
          author
          duration
          components {
            section
            exerciseVariantId
            exerciseId
            name
            pause
            duration
            speedFactor
            intensity
            video {
              filepath
              filesize
              __typename
            }
            equipments
            painAreas
            cardiacImpact
            easyMode {
              exerciseId
              exerciseVariantId
              name
              equipments
              cardiacImpact
              advice
              __typename
            }
            advice
            walkSessionData {
              distance
              averageSpeed
              averagePace
              __typename
            }
            __typename
          }
          equipments
          module
          moduleLevel
          program
          iterations
          walkSessionData {
            distance
            averageSpeed
            averagePace
            __typename
          }
          type
          description
          advice
          __typename
        }
        sessionInfo {
          startTime
          endTime
          userFeedback
          cardiacFeedback
          sensorDataTimeseries {
            hr {
              min
              max
              avg
              __typename
            }
            __typename
          }
          __typename
        }
        timestamp
        userId
        sensor {
          modelName
          name
          tag
          type
          additionalData {
            macAddress
            __typename
          }
          brand
          __typename
        }
        sessionTimeSeries
        userInfo {
          dateOfBirth
          weight
          height
          hrMin
          hrMax
          gender
          __typename
        }
        program
        type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAlixMedHistory = /* GraphQL */ `
  query GetAlixMedHistory($id: ID!) {
    getAlixMedHistory(id: $id) {
      id
      userId
      timestamp
      event
      data {
        source
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAlixMedHistories = /* GraphQL */ `
  query ListAlixMedHistories(
    $filter: ModelAlixMedHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlixMedHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        timestamp
        event
        data {
          source
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAlixMedEventLog = /* GraphQL */ `
  query GetAlixMedEventLog($id: ID!) {
    getAlixMedEventLog(id: $id) {
      id
      userId
      timestamp
      eventType
      ref
      data
      title
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAlixMedEventLogs = /* GraphQL */ `
  query ListAlixMedEventLogs(
    $filter: ModelAlixMedEventLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlixMedEventLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        timestamp
        eventType
        ref
        data
        title
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAlixMedActivityData = /* GraphQL */ `
  query GetAlixMedActivityData($id: ID!) {
    getAlixMedActivityData(id: $id) {
      id
      userId
      date
      endTime
      startTime
      dataValues {
        activityType
        value
        __typename
      }
      granularity
      __typename
    }
  }
`;
export const listAlixMedActivityData = /* GraphQL */ `
  query ListAlixMedActivityData(
    $filter: ModelAlixMedActivityDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlixMedActivityData(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        date
        endTime
        startTime
        dataValues {
          activityType
          value
          __typename
        }
        granularity
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAlixMedInviteCode = /* GraphQL */ `
  query GetAlixMedInviteCode($id: String!) {
    getAlixMedInviteCode(id: $id) {
      id
      name
      program
      codeStatus
      refKey
      withExpiration
      expiresAt
      withCounts
      uses
      maxUses
      withUserValidation
      userValidationMethod
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAlixMedInviteCodes = /* GraphQL */ `
  query ListAlixMedInviteCodes(
    $id: String
    $filter: ModelAlixMedInviteCodeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAlixMedInviteCodes(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        program
        codeStatus
        refKey
        withExpiration
        expiresAt
        withCounts
        uses
        maxUses
        withUserValidation
        userValidationMethod
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAlixMedUserProgramParams = /* GraphQL */ `
  query GetAlixMedUserProgramParams($id: ID!) {
    getAlixMedUserProgramParams(id: $id) {
      id
      userId
      timestamp
      status
      questionnaireId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAlixMedUserProgramParams = /* GraphQL */ `
  query ListAlixMedUserProgramParams(
    $filter: ModelAlixMedUserProgramParamsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlixMedUserProgramParams(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        timestamp
        status
        questionnaireId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAlixMedProgram = /* GraphQL */ `
  query GetAlixMedProgram($id: String!) {
    getAlixMedProgram(id: $id) {
      id
      displayName
      description
      icon
      lastInsertPatientId
      status
      questionnaires {
        id
        questions {
          id
          type
          enum
          enumMultiple
          __typename
        }
        __typename
      }
      options {
        indoorSessions
        walkSessions
        __typename
      }
      generateSessionMode
      aiParams {
        recommendations {
          api
          apiId
          promptTemplate
          __typename
        }
        session {
          model
          programRules
          promptTemplate
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAlixMedPrograms = /* GraphQL */ `
  query ListAlixMedPrograms(
    $id: String
    $filter: ModelAlixMedProgramFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAlixMedPrograms(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        displayName
        description
        icon
        lastInsertPatientId
        status
        questionnaires {
          id
          questions {
            id
            type
            enum
            enumMultiple
            __typename
          }
          __typename
        }
        options {
          indoorSessions
          walkSessions
          __typename
        }
        generateSessionMode
        aiParams {
          recommendations {
            api
            apiId
            promptTemplate
            __typename
          }
          session {
            model
            programRules
            promptTemplate
            __typename
          }
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getINCRGaitSpeedTest = /* GraphQL */ `
  query GetINCRGaitSpeedTest($id: ID!) {
    getINCRGaitSpeedTest(id: $id) {
      id
      userId
      timestamp
      value
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listINCRGaitSpeedTests = /* GraphQL */ `
  query ListINCRGaitSpeedTests(
    $filter: ModelINCRGaitSpeedTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listINCRGaitSpeedTests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        timestamp
        value
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listAlixMedExercisesByIntensity = /* GraphQL */ `
  query ListAlixMedExercisesByIntensity(
    $intensity: Int!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAlixMedExerciseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlixMedExercisesByIntensity(
      intensity: $intensity
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        advice {
          fr
          en
          nl
          es
          it
          de
          __typename
        }
        bodyParts {
          id
          __typename
        }
        capacity
        cardiacImpact
        categories {
          id
          __typename
        }
        difficulty
        equipments {
          id
          name
          __typename
        }
        painAreas
        family
        intensity
        muscularContraction
        musclesInvolved {
          id
          __typename
        }
        name
        tags
        variants {
          id
          name
          videos {
            alex {
              __typename
            }
            nina {
              __typename
            }
            __typename
          }
          __typename
        }
        workoutPrograms
        easyIDs
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listAlixMedExercisesByName = /* GraphQL */ `
  query ListAlixMedExercisesByName(
    $name: String!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAlixMedExerciseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlixMedExercisesByName(
      name: $name
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        advice {
          fr
          en
          nl
          es
          it
          de
          __typename
        }
        bodyParts {
          id
          __typename
        }
        capacity
        cardiacImpact
        categories {
          id
          __typename
        }
        difficulty
        equipments {
          id
          name
          __typename
        }
        painAreas
        family
        intensity
        muscularContraction
        musclesInvolved {
          id
          __typename
        }
        name
        tags
        variants {
          id
          name
          videos {
            alex {
              __typename
            }
            nina {
              __typename
            }
            __typename
          }
          __typename
        }
        workoutPrograms
        easyIDs
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listSessionByUserId = /* GraphQL */ `
  query ListSessionByUserId(
    $userId: String!
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAlixMedSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSessionByUserId(
      userId: $userId
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        events {
          type
          timestamp
          data {
            index
            type
            __typename
          }
          __typename
        }
        exercisesData {
          name
          author
          duration
          components {
            section
            exerciseVariantId
            exerciseId
            name
            pause
            duration
            speedFactor
            intensity
            video {
              filepath
              filesize
              __typename
            }
            equipments
            painAreas
            cardiacImpact
            easyMode {
              exerciseId
              exerciseVariantId
              name
              equipments
              cardiacImpact
              advice
              __typename
            }
            advice
            walkSessionData {
              distance
              averageSpeed
              averagePace
              __typename
            }
            __typename
          }
          equipments
          module
          moduleLevel
          program
          iterations
          walkSessionData {
            distance
            averageSpeed
            averagePace
            __typename
          }
          type
          description
          advice
          __typename
        }
        sessionInfo {
          startTime
          endTime
          userFeedback
          cardiacFeedback
          sensorDataTimeseries {
            hr {
              min
              max
              avg
              __typename
            }
            __typename
          }
          __typename
        }
        timestamp
        userId
        sensor {
          modelName
          name
          tag
          type
          additionalData {
            macAddress
            __typename
          }
          brand
          __typename
        }
        sessionTimeSeries
        userInfo {
          dateOfBirth
          weight
          height
          hrMin
          hrMax
          gender
          __typename
        }
        program
        type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const subscriptionHistoryByUserSortedByTimestamp = /* GraphQL */ `
  query SubscriptionHistoryByUserSortedByTimestamp(
    $userId: String!
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAlixMedHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    subscriptionHistoryByUserSortedByTimestamp(
      userId: $userId
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        timestamp
        event
        data {
          source
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const eventLogsByUserSortedByTimestamp = /* GraphQL */ `
  query EventLogsByUserSortedByTimestamp(
    $userId: String!
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAlixMedEventLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventLogsByUserSortedByTimestamp(
      userId: $userId
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        timestamp
        eventType
        ref
        data
        title
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const eventLogByUserEventTypeSortedByTimestamp = /* GraphQL */ `
  query EventLogByUserEventTypeSortedByTimestamp(
    $eventType: String!
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAlixMedEventLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventLogByUserEventTypeSortedByTimestamp(
      eventType: $eventType
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        timestamp
        eventType
        ref
        data
        title
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listAlixMedActivityDataByUserId = /* GraphQL */ `
  query ListAlixMedActivityDataByUserId(
    $userId: String!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAlixMedActivityDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlixMedActivityDataByUserId(
      userId: $userId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        date
        endTime
        startTime
        dataValues {
          activityType
          value
          __typename
        }
        granularity
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listAlixMedActivityDataByGranularityAndDate = /* GraphQL */ `
  query ListAlixMedActivityDataByGranularityAndDate(
    $granularity: ActivityDataGranularity!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAlixMedActivityDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlixMedActivityDataByGranularityAndDate(
      granularity: $granularity
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        date
        endTime
        startTime
        dataValues {
          activityType
          value
          __typename
        }
        granularity
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listAlixMedInviteCodesByName = /* GraphQL */ `
  query ListAlixMedInviteCodesByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAlixMedInviteCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlixMedInviteCodesByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        program
        codeStatus
        refKey
        withExpiration
        expiresAt
        withCounts
        uses
        maxUses
        withUserValidation
        userValidationMethod
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listAlixMedInviteCodesByProgrId = /* GraphQL */ `
  query ListAlixMedInviteCodesByProgrId(
    $program: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAlixMedInviteCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlixMedInviteCodesByProgrId(
      program: $program
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        program
        codeStatus
        refKey
        withExpiration
        expiresAt
        withCounts
        uses
        maxUses
        withUserValidation
        userValidationMethod
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listAlixMedInviteCodesByRefKey = /* GraphQL */ `
  query ListAlixMedInviteCodesByRefKey(
    $refKey: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAlixMedInviteCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlixMedInviteCodesByRefKey(
      refKey: $refKey
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        program
        codeStatus
        refKey
        withExpiration
        expiresAt
        withCounts
        uses
        maxUses
        withUserValidation
        userValidationMethod
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProgramParamsByUserSortedByTimestamp = /* GraphQL */ `
  query GetProgramParamsByUserSortedByTimestamp(
    $userId: String!
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAlixMedUserProgramParamsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getProgramParamsByUserSortedByTimestamp(
      userId: $userId
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        timestamp
        status
        questionnaireId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAlixMedUser = /* GraphQL */ `
  query GetAlixMedUser($id: ID!) {
    getAlixMedUser(id: $id) {
      id
      patientId
      dateOfBirth
      email
      phoneNumber
      equipments
      painAreas
      firstName
      locale
      program
      membership {
        refKey
        inviteToken
        __typename
      }
      membershipStatus
      membershipStartedAt
      gender
      hrMax
      hrMin
      name
      sensorsConfigured {
        modelName
        name
        tag
        type
        additionalData {
          macAddress
          __typename
        }
        brand
        __typename
      }
      sessionsCompleted
      walkSessionsCompleted
      weight
      height
      versionInfo {
        appVersion
        os
        osVersion
        platform
        version
        __typename
      }
      avatar
      balance
      balanceLevel
      balanceCardiacFeedback
      balanceFeedback
      endurance
      enduranceLevel
      enduranceCardiacFeedback
      enduranceFeedback
      power
      powerLevel
      powerCardiacFeedback
      powerFeedback
      resistance
      resistanceLevel
      resistanceCardiacFeedback
      resistanceFeedback
      recommendations {
        value
        input
        createdAt
        expiresAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listAlixMedUsers = /* GraphQL */ `
  query ListAlixMedUsers(
    $filter: ModelAlixMedUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlixMedUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        patientId
        dateOfBirth
        email
        phoneNumber
        equipments
        painAreas
        firstName
        locale
        program
        membership {
          refKey
          inviteToken
          __typename
        }
        membershipStatus
        membershipStartedAt
        gender
        hrMax
        hrMin
        name
        sensorsConfigured {
          modelName
          name
          tag
          type
          additionalData {
            macAddress
            __typename
          }
          brand
          __typename
        }
        sessionsCompleted
        walkSessionsCompleted
        weight
        height
        versionInfo {
          appVersion
          os
          osVersion
          platform
          version
          __typename
        }
        avatar
        balance
        balanceLevel
        balanceCardiacFeedback
        balanceFeedback
        endurance
        enduranceLevel
        enduranceCardiacFeedback
        enduranceFeedback
        power
        powerLevel
        powerCardiacFeedback
        powerFeedback
        resistance
        resistanceLevel
        resistanceCardiacFeedback
        resistanceFeedback
        recommendations {
          value
          input
          createdAt
          expiresAt
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listAlixMedUsersByPatientId = /* GraphQL */ `
  query ListAlixMedUsersByPatientId(
    $patientId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAlixMedUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlixMedUsersByPatientId(
      patientId: $patientId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        patientId
        dateOfBirth
        email
        phoneNumber
        equipments
        painAreas
        firstName
        locale
        program
        membership {
          refKey
          inviteToken
          __typename
        }
        membershipStatus
        membershipStartedAt
        gender
        hrMax
        hrMin
        name
        sensorsConfigured {
          modelName
          name
          tag
          type
          additionalData {
            macAddress
            __typename
          }
          brand
          __typename
        }
        sessionsCompleted
        walkSessionsCompleted
        weight
        height
        versionInfo {
          appVersion
          os
          osVersion
          platform
          version
          __typename
        }
        avatar
        balance
        balanceLevel
        balanceCardiacFeedback
        balanceFeedback
        endurance
        enduranceLevel
        enduranceCardiacFeedback
        enduranceFeedback
        power
        powerLevel
        powerCardiacFeedback
        powerFeedback
        resistance
        resistanceLevel
        resistanceCardiacFeedback
        resistanceFeedback
        recommendations {
          value
          input
          createdAt
          expiresAt
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listUsersByEmail = /* GraphQL */ `
  query ListUsersByEmail(
    $email: AWSEmail!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAlixMedUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersByEmail(
      email: $email
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        patientId
        dateOfBirth
        email
        phoneNumber
        equipments
        painAreas
        firstName
        locale
        program
        membership {
          refKey
          inviteToken
          __typename
        }
        membershipStatus
        membershipStartedAt
        gender
        hrMax
        hrMin
        name
        sensorsConfigured {
          modelName
          name
          tag
          type
          additionalData {
            macAddress
            __typename
          }
          brand
          __typename
        }
        sessionsCompleted
        walkSessionsCompleted
        weight
        height
        versionInfo {
          appVersion
          os
          osVersion
          platform
          version
          __typename
        }
        avatar
        balance
        balanceLevel
        balanceCardiacFeedback
        balanceFeedback
        endurance
        enduranceLevel
        enduranceCardiacFeedback
        enduranceFeedback
        power
        powerLevel
        powerCardiacFeedback
        powerFeedback
        resistance
        resistanceLevel
        resistanceCardiacFeedback
        resistanceFeedback
        recommendations {
          value
          input
          createdAt
          expiresAt
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listUsersByPhoneNumber = /* GraphQL */ `
  query ListUsersByPhoneNumber(
    $phoneNumber: AWSPhone!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAlixMedUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersByPhoneNumber(
      phoneNumber: $phoneNumber
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        patientId
        dateOfBirth
        email
        phoneNumber
        equipments
        painAreas
        firstName
        locale
        program
        membership {
          refKey
          inviteToken
          __typename
        }
        membershipStatus
        membershipStartedAt
        gender
        hrMax
        hrMin
        name
        sensorsConfigured {
          modelName
          name
          tag
          type
          additionalData {
            macAddress
            __typename
          }
          brand
          __typename
        }
        sessionsCompleted
        walkSessionsCompleted
        weight
        height
        versionInfo {
          appVersion
          os
          osVersion
          platform
          version
          __typename
        }
        avatar
        balance
        balanceLevel
        balanceCardiacFeedback
        balanceFeedback
        endurance
        enduranceLevel
        enduranceCardiacFeedback
        enduranceFeedback
        power
        powerLevel
        powerCardiacFeedback
        powerFeedback
        resistance
        resistanceLevel
        resistanceCardiacFeedback
        resistanceFeedback
        recommendations {
          value
          input
          createdAt
          expiresAt
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listAlixMedUsersByProgrId = /* GraphQL */ `
  query ListAlixMedUsersByProgrId(
    $program: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAlixMedUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlixMedUsersByProgrId(
      program: $program
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        patientId
        dateOfBirth
        email
        phoneNumber
        equipments
        painAreas
        firstName
        locale
        program
        membership {
          refKey
          inviteToken
          __typename
        }
        membershipStatus
        membershipStartedAt
        gender
        hrMax
        hrMin
        name
        sensorsConfigured {
          modelName
          name
          tag
          type
          additionalData {
            macAddress
            __typename
          }
          brand
          __typename
        }
        sessionsCompleted
        walkSessionsCompleted
        weight
        height
        versionInfo {
          appVersion
          os
          osVersion
          platform
          version
          __typename
        }
        avatar
        balance
        balanceLevel
        balanceCardiacFeedback
        balanceFeedback
        endurance
        enduranceLevel
        enduranceCardiacFeedback
        enduranceFeedback
        power
        powerLevel
        powerCardiacFeedback
        powerFeedback
        resistance
        resistanceLevel
        resistanceCardiacFeedback
        resistanceFeedback
        recommendations {
          value
          input
          createdAt
          expiresAt
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
