/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-3",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://p9kemmsca6.execute-api.eu-west-3.amazonaws.com/prod",
            "region": "eu-west-3"
        },
        {
            "name": "alixMedRestApi",
            "endpoint": "https://fswu6ssym1.execute-api.eu-west-3.amazonaws.com/prod",
            "region": "eu-west-3"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://3atvtgq6jjbl5ocwqzgwo6tvf4.appsync-api.eu-west-3.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-3",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-west-3:8c75086b-0fc9-4630-88b3-696596c9bbe5",
    "aws_cognito_region": "eu-west-3",
    "aws_user_pools_id": "eu-west-3_fXWjtVopc",
    "aws_user_pools_web_client_id": "25jhuaidljvi48ubuqa3ictre0",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "alix-med-hosting-bucket-prod",
    "aws_content_delivery_bucket_region": "eu-west-3",
    "aws_content_delivery_url": "https://dgso3coiuhead.cloudfront.net",
    "aws_user_files_s3_bucket": "alix-med-storage-bucket155130-prod",
    "aws_user_files_s3_bucket_region": "eu-west-3"
};


export default awsmobile;
