/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const syncActivityData = /* GraphQL */ `
  mutation SyncActivityData($input: syncActivityDataInput!) {
    syncActivityData(input: $input) {
      syncStatus
      __typename
    }
  }
`;
export const generateSession = /* GraphQL */ `
  mutation GenerateSession($input: generateSessionInput!) {
    generateSession(input: $input) {
      name
      author
      duration
      components {
        section
        exerciseVariantId
        exerciseId
        name
        pause
        duration
        speedFactor
        intensity
        video {
          filepath
          filesize
          __typename
        }
        equipments
        painAreas
        cardiacImpact
        easyMode {
          exerciseId
          exerciseVariantId
          name
          video {
            filepath
            filesize
            __typename
          }
          equipments
          cardiacImpact
          advice
          __typename
        }
        advice
        walkSessionData {
          distance
          averageSpeed
          averagePace
          __typename
        }
        __typename
      }
      equipments
      painAreas
      module
      moduleLevel
      iterations
      program
      sessionsCompleted
      lastDataValue
      type
      description
      advice
      __typename
    }
  }
`;
export const uploadAlixAppSession = /* GraphQL */ `
  mutation UploadAlixAppSession($input: CreateSessionInput!) {
    uploadAlixAppSession(input: $input) {
      id
      events {
        type
        timestamp
        data {
          index
          type
          __typename
        }
        __typename
      }
      exercisesData {
        name
        author
        duration
        components {
          section
          exerciseVariantId
          exerciseId
          name
          pause
          duration
          speedFactor
          intensity
          video {
            filepath
            filesize
            __typename
          }
          equipments
          painAreas
          cardiacImpact
          easyMode {
            exerciseId
            exerciseVariantId
            name
            video {
              filepath
              filesize
              __typename
            }
            equipments
            cardiacImpact
            advice
            __typename
          }
          advice
          walkSessionData {
            distance
            averageSpeed
            averagePace
            __typename
          }
          __typename
        }
        equipments
        module
        moduleLevel
        program
        iterations
        walkSessionData {
          distance
          averageSpeed
          averagePace
          __typename
        }
        type
        description
        advice
        __typename
      }
      userId
      sensor {
        modelName
        name
        tag
        type
        additionalData {
          macAddress
          __typename
        }
        brand
        __typename
      }
      sessionInfo {
        startTime
        endTime
        userFeedback
        cardiacFeedback
        sensorDataTimeseries {
          hr {
            min
            max
            avg
            __typename
          }
          __typename
        }
        __typename
      }
      sessionTimeSeries
      userInfo {
        dateOfBirth
        weight
        height
        hrMin
        hrMax
        gender
        __typename
      }
      timestamp
      type
      __typename
    }
  }
`;
export const createAlixMedExercise = /* GraphQL */ `
  mutation CreateAlixMedExercise(
    $input: CreateAlixMedExerciseInput!
    $condition: ModelAlixMedExerciseConditionInput
  ) {
    createAlixMedExercise(input: $input, condition: $condition) {
      id
      advice {
        fr
        en
        nl
        es
        it
        de
        __typename
      }
      bodyParts {
        id
        __typename
      }
      capacity
      cardiacImpact
      categories {
        id
        __typename
      }
      difficulty
      equipments {
        id
        name
        __typename
      }
      painAreas
      family
      intensity
      muscularContraction
      musclesInvolved {
        id
        __typename
      }
      name
      tags
      variants {
        id
        name
        videos {
          alex {
            default {
              filepath
              fileSize
              __typename
            }
            high {
              filepath
              fileSize
              __typename
            }
            __typename
          }
          nina {
            default {
              filepath
              fileSize
              __typename
            }
            high {
              filepath
              fileSize
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      workoutPrograms
      easyIDs
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAlixMedExercise = /* GraphQL */ `
  mutation UpdateAlixMedExercise(
    $input: UpdateAlixMedExerciseInput!
    $condition: ModelAlixMedExerciseConditionInput
  ) {
    updateAlixMedExercise(input: $input, condition: $condition) {
      id
      advice {
        fr
        en
        nl
        es
        it
        de
        __typename
      }
      bodyParts {
        id
        __typename
      }
      capacity
      cardiacImpact
      categories {
        id
        __typename
      }
      difficulty
      equipments {
        id
        name
        __typename
      }
      painAreas
      family
      intensity
      muscularContraction
      musclesInvolved {
        id
        __typename
      }
      name
      tags
      variants {
        id
        name
        videos {
          alex {
            default {
              filepath
              fileSize
              __typename
            }
            high {
              filepath
              fileSize
              __typename
            }
            __typename
          }
          nina {
            default {
              filepath
              fileSize
              __typename
            }
            high {
              filepath
              fileSize
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      workoutPrograms
      easyIDs
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAlixMedExercise = /* GraphQL */ `
  mutation DeleteAlixMedExercise(
    $input: DeleteAlixMedExerciseInput!
    $condition: ModelAlixMedExerciseConditionInput
  ) {
    deleteAlixMedExercise(input: $input, condition: $condition) {
      id
      advice {
        fr
        en
        nl
        es
        it
        de
        __typename
      }
      bodyParts {
        id
        __typename
      }
      capacity
      cardiacImpact
      categories {
        id
        __typename
      }
      difficulty
      equipments {
        id
        name
        __typename
      }
      painAreas
      family
      intensity
      muscularContraction
      musclesInvolved {
        id
        __typename
      }
      name
      tags
      variants {
        id
        name
        videos {
          alex {
            default {
              filepath
              fileSize
              __typename
            }
            high {
              filepath
              fileSize
              __typename
            }
            __typename
          }
          nina {
            default {
              filepath
              fileSize
              __typename
            }
            high {
              filepath
              fileSize
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      workoutPrograms
      easyIDs
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAlixMedSession = /* GraphQL */ `
  mutation CreateAlixMedSession(
    $input: CreateAlixMedSessionInput!
    $condition: ModelAlixMedSessionConditionInput
  ) {
    createAlixMedSession(input: $input, condition: $condition) {
      id
      events {
        type
        timestamp
        data {
          index
          type
          __typename
        }
        __typename
      }
      exercisesData {
        name
        author
        duration
        components {
          section
          exerciseVariantId
          exerciseId
          name
          pause
          duration
          speedFactor
          intensity
          video {
            filepath
            filesize
            __typename
          }
          equipments
          painAreas
          cardiacImpact
          easyMode {
            exerciseId
            exerciseVariantId
            name
            video {
              filepath
              filesize
              __typename
            }
            equipments
            cardiacImpact
            advice
            __typename
          }
          advice
          walkSessionData {
            distance
            averageSpeed
            averagePace
            __typename
          }
          __typename
        }
        equipments
        module
        moduleLevel
        program
        iterations
        walkSessionData {
          distance
          averageSpeed
          averagePace
          __typename
        }
        type
        description
        advice
        __typename
      }
      sessionInfo {
        startTime
        endTime
        userFeedback
        cardiacFeedback
        sensorDataTimeseries {
          hr {
            min
            max
            avg
            __typename
          }
          __typename
        }
        __typename
      }
      timestamp
      userId
      sensor {
        modelName
        name
        tag
        type
        additionalData {
          macAddress
          __typename
        }
        brand
        __typename
      }
      sessionTimeSeries
      userInfo {
        dateOfBirth
        weight
        height
        hrMin
        hrMax
        gender
        __typename
      }
      program
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAlixMedSession = /* GraphQL */ `
  mutation UpdateAlixMedSession(
    $input: UpdateAlixMedSessionInput!
    $condition: ModelAlixMedSessionConditionInput
  ) {
    updateAlixMedSession(input: $input, condition: $condition) {
      id
      events {
        type
        timestamp
        data {
          index
          type
          __typename
        }
        __typename
      }
      exercisesData {
        name
        author
        duration
        components {
          section
          exerciseVariantId
          exerciseId
          name
          pause
          duration
          speedFactor
          intensity
          video {
            filepath
            filesize
            __typename
          }
          equipments
          painAreas
          cardiacImpact
          easyMode {
            exerciseId
            exerciseVariantId
            name
            video {
              filepath
              filesize
              __typename
            }
            equipments
            cardiacImpact
            advice
            __typename
          }
          advice
          walkSessionData {
            distance
            averageSpeed
            averagePace
            __typename
          }
          __typename
        }
        equipments
        module
        moduleLevel
        program
        iterations
        walkSessionData {
          distance
          averageSpeed
          averagePace
          __typename
        }
        type
        description
        advice
        __typename
      }
      sessionInfo {
        startTime
        endTime
        userFeedback
        cardiacFeedback
        sensorDataTimeseries {
          hr {
            min
            max
            avg
            __typename
          }
          __typename
        }
        __typename
      }
      timestamp
      userId
      sensor {
        modelName
        name
        tag
        type
        additionalData {
          macAddress
          __typename
        }
        brand
        __typename
      }
      sessionTimeSeries
      userInfo {
        dateOfBirth
        weight
        height
        hrMin
        hrMax
        gender
        __typename
      }
      program
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAlixMedSession = /* GraphQL */ `
  mutation DeleteAlixMedSession(
    $input: DeleteAlixMedSessionInput!
    $condition: ModelAlixMedSessionConditionInput
  ) {
    deleteAlixMedSession(input: $input, condition: $condition) {
      id
      events {
        type
        timestamp
        data {
          index
          type
          __typename
        }
        __typename
      }
      exercisesData {
        name
        author
        duration
        components {
          section
          exerciseVariantId
          exerciseId
          name
          pause
          duration
          speedFactor
          intensity
          video {
            filepath
            filesize
            __typename
          }
          equipments
          painAreas
          cardiacImpact
          easyMode {
            exerciseId
            exerciseVariantId
            name
            video {
              filepath
              filesize
              __typename
            }
            equipments
            cardiacImpact
            advice
            __typename
          }
          advice
          walkSessionData {
            distance
            averageSpeed
            averagePace
            __typename
          }
          __typename
        }
        equipments
        module
        moduleLevel
        program
        iterations
        walkSessionData {
          distance
          averageSpeed
          averagePace
          __typename
        }
        type
        description
        advice
        __typename
      }
      sessionInfo {
        startTime
        endTime
        userFeedback
        cardiacFeedback
        sensorDataTimeseries {
          hr {
            min
            max
            avg
            __typename
          }
          __typename
        }
        __typename
      }
      timestamp
      userId
      sensor {
        modelName
        name
        tag
        type
        additionalData {
          macAddress
          __typename
        }
        brand
        __typename
      }
      sessionTimeSeries
      userInfo {
        dateOfBirth
        weight
        height
        hrMin
        hrMax
        gender
        __typename
      }
      program
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAlixMedHistory = /* GraphQL */ `
  mutation CreateAlixMedHistory(
    $input: CreateAlixMedHistoryInput!
    $condition: ModelAlixMedHistoryConditionInput
  ) {
    createAlixMedHistory(input: $input, condition: $condition) {
      id
      userId
      timestamp
      event
      data {
        source
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAlixMedHistory = /* GraphQL */ `
  mutation UpdateAlixMedHistory(
    $input: UpdateAlixMedHistoryInput!
    $condition: ModelAlixMedHistoryConditionInput
  ) {
    updateAlixMedHistory(input: $input, condition: $condition) {
      id
      userId
      timestamp
      event
      data {
        source
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAlixMedHistory = /* GraphQL */ `
  mutation DeleteAlixMedHistory(
    $input: DeleteAlixMedHistoryInput!
    $condition: ModelAlixMedHistoryConditionInput
  ) {
    deleteAlixMedHistory(input: $input, condition: $condition) {
      id
      userId
      timestamp
      event
      data {
        source
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAlixMedEventLog = /* GraphQL */ `
  mutation CreateAlixMedEventLog(
    $input: CreateAlixMedEventLogInput!
    $condition: ModelAlixMedEventLogConditionInput
  ) {
    createAlixMedEventLog(input: $input, condition: $condition) {
      id
      userId
      timestamp
      eventType
      ref
      data
      title
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAlixMedEventLog = /* GraphQL */ `
  mutation UpdateAlixMedEventLog(
    $input: UpdateAlixMedEventLogInput!
    $condition: ModelAlixMedEventLogConditionInput
  ) {
    updateAlixMedEventLog(input: $input, condition: $condition) {
      id
      userId
      timestamp
      eventType
      ref
      data
      title
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAlixMedEventLog = /* GraphQL */ `
  mutation DeleteAlixMedEventLog(
    $input: DeleteAlixMedEventLogInput!
    $condition: ModelAlixMedEventLogConditionInput
  ) {
    deleteAlixMedEventLog(input: $input, condition: $condition) {
      id
      userId
      timestamp
      eventType
      ref
      data
      title
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAlixMedActivityData = /* GraphQL */ `
  mutation CreateAlixMedActivityData(
    $input: CreateAlixMedActivityDataInput!
    $condition: ModelAlixMedActivityDataConditionInput
  ) {
    createAlixMedActivityData(input: $input, condition: $condition) {
      id
      userId
      date
      endTime
      startTime
      dataValues {
        activityType
        value
        __typename
      }
      granularity
      __typename
    }
  }
`;
export const updateAlixMedActivityData = /* GraphQL */ `
  mutation UpdateAlixMedActivityData(
    $input: UpdateAlixMedActivityDataInput!
    $condition: ModelAlixMedActivityDataConditionInput
  ) {
    updateAlixMedActivityData(input: $input, condition: $condition) {
      id
      userId
      date
      endTime
      startTime
      dataValues {
        activityType
        value
        __typename
      }
      granularity
      __typename
    }
  }
`;
export const deleteAlixMedActivityData = /* GraphQL */ `
  mutation DeleteAlixMedActivityData(
    $input: DeleteAlixMedActivityDataInput!
    $condition: ModelAlixMedActivityDataConditionInput
  ) {
    deleteAlixMedActivityData(input: $input, condition: $condition) {
      id
      userId
      date
      endTime
      startTime
      dataValues {
        activityType
        value
        __typename
      }
      granularity
      __typename
    }
  }
`;
export const createAlixMedInviteCode = /* GraphQL */ `
  mutation CreateAlixMedInviteCode(
    $input: CreateAlixMedInviteCodeInput!
    $condition: ModelAlixMedInviteCodeConditionInput
  ) {
    createAlixMedInviteCode(input: $input, condition: $condition) {
      id
      name
      program
      codeStatus
      refKey
      withExpiration
      expiresAt
      withCounts
      uses
      maxUses
      withUserValidation
      userValidationMethod
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAlixMedInviteCode = /* GraphQL */ `
  mutation UpdateAlixMedInviteCode(
    $input: UpdateAlixMedInviteCodeInput!
    $condition: ModelAlixMedInviteCodeConditionInput
  ) {
    updateAlixMedInviteCode(input: $input, condition: $condition) {
      id
      name
      program
      codeStatus
      refKey
      withExpiration
      expiresAt
      withCounts
      uses
      maxUses
      withUserValidation
      userValidationMethod
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAlixMedInviteCode = /* GraphQL */ `
  mutation DeleteAlixMedInviteCode(
    $input: DeleteAlixMedInviteCodeInput!
    $condition: ModelAlixMedInviteCodeConditionInput
  ) {
    deleteAlixMedInviteCode(input: $input, condition: $condition) {
      id
      name
      program
      codeStatus
      refKey
      withExpiration
      expiresAt
      withCounts
      uses
      maxUses
      withUserValidation
      userValidationMethod
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAlixMedUserProgramParams = /* GraphQL */ `
  mutation CreateAlixMedUserProgramParams(
    $input: CreateAlixMedUserProgramParamsInput!
    $condition: ModelAlixMedUserProgramParamsConditionInput
  ) {
    createAlixMedUserProgramParams(input: $input, condition: $condition) {
      id
      userId
      timestamp
      status
      questionnaireId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAlixMedUserProgramParams = /* GraphQL */ `
  mutation UpdateAlixMedUserProgramParams(
    $input: UpdateAlixMedUserProgramParamsInput!
    $condition: ModelAlixMedUserProgramParamsConditionInput
  ) {
    updateAlixMedUserProgramParams(input: $input, condition: $condition) {
      id
      userId
      timestamp
      status
      questionnaireId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAlixMedUserProgramParams = /* GraphQL */ `
  mutation DeleteAlixMedUserProgramParams(
    $input: DeleteAlixMedUserProgramParamsInput!
    $condition: ModelAlixMedUserProgramParamsConditionInput
  ) {
    deleteAlixMedUserProgramParams(input: $input, condition: $condition) {
      id
      userId
      timestamp
      status
      questionnaireId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAlixMedProgram = /* GraphQL */ `
  mutation CreateAlixMedProgram(
    $input: CreateAlixMedProgramInput!
    $condition: ModelAlixMedProgramConditionInput
  ) {
    createAlixMedProgram(input: $input, condition: $condition) {
      id
      displayName
      description
      icon
      lastInsertPatientId
      status
      questionnaires {
        id
        questions {
          id
          type
          enum
          enumMultiple
          __typename
        }
        __typename
      }
      options {
        indoorSessions
        walkSessions
        __typename
      }
      generateSessionMode
      aiParams {
        recommendations {
          api
          apiId
          promptTemplate
          __typename
        }
        session {
          model
          programRules
          promptTemplate
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAlixMedProgram = /* GraphQL */ `
  mutation UpdateAlixMedProgram(
    $input: UpdateAlixMedProgramInput!
    $condition: ModelAlixMedProgramConditionInput
  ) {
    updateAlixMedProgram(input: $input, condition: $condition) {
      id
      displayName
      description
      icon
      lastInsertPatientId
      status
      questionnaires {
        id
        questions {
          id
          type
          enum
          enumMultiple
          __typename
        }
        __typename
      }
      options {
        indoorSessions
        walkSessions
        __typename
      }
      generateSessionMode
      aiParams {
        recommendations {
          api
          apiId
          promptTemplate
          __typename
        }
        session {
          model
          programRules
          promptTemplate
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAlixMedProgram = /* GraphQL */ `
  mutation DeleteAlixMedProgram(
    $input: DeleteAlixMedProgramInput!
    $condition: ModelAlixMedProgramConditionInput
  ) {
    deleteAlixMedProgram(input: $input, condition: $condition) {
      id
      displayName
      description
      icon
      lastInsertPatientId
      status
      questionnaires {
        id
        questions {
          id
          type
          enum
          enumMultiple
          __typename
        }
        __typename
      }
      options {
        indoorSessions
        walkSessions
        __typename
      }
      generateSessionMode
      aiParams {
        recommendations {
          api
          apiId
          promptTemplate
          __typename
        }
        session {
          model
          programRules
          promptTemplate
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createINCRGaitSpeedTest = /* GraphQL */ `
  mutation CreateINCRGaitSpeedTest(
    $input: CreateINCRGaitSpeedTestInput!
    $condition: ModelINCRGaitSpeedTestConditionInput
  ) {
    createINCRGaitSpeedTest(input: $input, condition: $condition) {
      id
      userId
      timestamp
      value
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateINCRGaitSpeedTest = /* GraphQL */ `
  mutation UpdateINCRGaitSpeedTest(
    $input: UpdateINCRGaitSpeedTestInput!
    $condition: ModelINCRGaitSpeedTestConditionInput
  ) {
    updateINCRGaitSpeedTest(input: $input, condition: $condition) {
      id
      userId
      timestamp
      value
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteINCRGaitSpeedTest = /* GraphQL */ `
  mutation DeleteINCRGaitSpeedTest(
    $input: DeleteINCRGaitSpeedTestInput!
    $condition: ModelINCRGaitSpeedTestConditionInput
  ) {
    deleteINCRGaitSpeedTest(input: $input, condition: $condition) {
      id
      userId
      timestamp
      value
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAlixMedUser = /* GraphQL */ `
  mutation CreateAlixMedUser(
    $input: CreateAlixMedUserInput!
    $condition: ModelAlixMedUserConditionInput
  ) {
    createAlixMedUser(input: $input, condition: $condition) {
      id
      patientId
      dateOfBirth
      email
      phoneNumber
      equipments
      painAreas
      firstName
      locale
      program
      membership {
        refKey
        inviteToken
        __typename
      }
      membershipStatus
      membershipStartedAt
      gender
      hrMax
      hrMin
      name
      sensorsConfigured {
        modelName
        name
        tag
        type
        additionalData {
          macAddress
          __typename
        }
        brand
        __typename
      }
      sessionsCompleted
      walkSessionsCompleted
      weight
      height
      versionInfo {
        appVersion
        os
        osVersion
        platform
        version
        __typename
      }
      avatar
      balance
      balanceLevel
      balanceCardiacFeedback
      balanceFeedback
      endurance
      enduranceLevel
      enduranceCardiacFeedback
      enduranceFeedback
      power
      powerLevel
      powerCardiacFeedback
      powerFeedback
      resistance
      resistanceLevel
      resistanceCardiacFeedback
      resistanceFeedback
      recommendations {
        value
        input
        createdAt
        expiresAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateAlixMedUser = /* GraphQL */ `
  mutation UpdateAlixMedUser(
    $input: UpdateAlixMedUserInput!
    $condition: ModelAlixMedUserConditionInput
  ) {
    updateAlixMedUser(input: $input, condition: $condition) {
      id
      patientId
      dateOfBirth
      email
      phoneNumber
      equipments
      painAreas
      firstName
      locale
      program
      membership {
        refKey
        inviteToken
        __typename
      }
      membershipStatus
      membershipStartedAt
      gender
      hrMax
      hrMin
      name
      sensorsConfigured {
        modelName
        name
        tag
        type
        additionalData {
          macAddress
          __typename
        }
        brand
        __typename
      }
      sessionsCompleted
      walkSessionsCompleted
      weight
      height
      versionInfo {
        appVersion
        os
        osVersion
        platform
        version
        __typename
      }
      avatar
      balance
      balanceLevel
      balanceCardiacFeedback
      balanceFeedback
      endurance
      enduranceLevel
      enduranceCardiacFeedback
      enduranceFeedback
      power
      powerLevel
      powerCardiacFeedback
      powerFeedback
      resistance
      resistanceLevel
      resistanceCardiacFeedback
      resistanceFeedback
      recommendations {
        value
        input
        createdAt
        expiresAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteAlixMedUser = /* GraphQL */ `
  mutation DeleteAlixMedUser(
    $input: DeleteAlixMedUserInput!
    $condition: ModelAlixMedUserConditionInput
  ) {
    deleteAlixMedUser(input: $input, condition: $condition) {
      id
      patientId
      dateOfBirth
      email
      phoneNumber
      equipments
      painAreas
      firstName
      locale
      program
      membership {
        refKey
        inviteToken
        __typename
      }
      membershipStatus
      membershipStartedAt
      gender
      hrMax
      hrMin
      name
      sensorsConfigured {
        modelName
        name
        tag
        type
        additionalData {
          macAddress
          __typename
        }
        brand
        __typename
      }
      sessionsCompleted
      walkSessionsCompleted
      weight
      height
      versionInfo {
        appVersion
        os
        osVersion
        platform
        version
        __typename
      }
      avatar
      balance
      balanceLevel
      balanceCardiacFeedback
      balanceFeedback
      endurance
      enduranceLevel
      enduranceCardiacFeedback
      enduranceFeedback
      power
      powerLevel
      powerCardiacFeedback
      powerFeedback
      resistance
      resistanceLevel
      resistanceCardiacFeedback
      resistanceFeedback
      recommendations {
        value
        input
        createdAt
        expiresAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
